@font-face {
    font-family: 'PP Woodland';
    src: url('/fonts/PPWoodland-Regular.woff2') format('woff2'),
        url('/fonts/PPWoodland-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Work Sans', sans-serif;
}

h1, h2, h3, h4, h5, h6{
    font-family:@font-secondary;
    font-weight:700;
    color:@primary-dark;
}

h1{
    font-size:48px;
    line-height:64px;
}

h2{
    font-size:36px;
    line-height:43px;
}

h3{
    font-size:30px;
    line-height:36px;
}

h4{
    font-size:18px;
    line-height:22px;
}

h5{
    font-size:16px;
    line-height:19px;
}

p{
    font-weight:300;
    font-size:18px;
    line-height:30px;
    color:@primary-dark;
}

.background-cover{
    background-position:center;
    background-size:cover;
}

.vertical-center{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index:3;
}

.horizontal-center{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index:3;
}

.absolute-center{
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
    z-index:3;
}

.animate{
    transition:@trans-all-slow;
}

.delay-100{transition-delay:@delay-100;}
.delay-200{transition-delay:@delay-200;}
.delay-300{transition-delay:@delay-300;}
.delay-400{transition-delay:@delay-400;}
.delay-500{transition-delay:@delay-500;}
.delay-600{transition-delay:@delay-600;}
.delay-700{transition-delay:@delay-700;}
.delay-800{transition-delay:@delay-800;}
.delay-900{transition-delay:@delay-900;}
.delay-1000{transition-delay:@delay-1000;}

.fade-in{
    opacity:0;

    &.active{
        opacity:1;
    }
}

.move-up{
    margin-top:20px;
    margin-bottom:-20px;

    &.active{
        margin-top:0px;
        margin-bottom:0px;
    }
}

.move-left{
    margin-left:20px;
    margin-right:-20px;

    &.active{
        margin-left:0px;
        margin-right:0px;
    }
}

.move-right{
    margin-right:20px;
    margin-left:-20px;

    &.active{
        margin-left:0px;
        margin-right:0px;
    }
}

.move-down{
    margin-top:-20px;

    &.active{
        margin-top:0px;
    }
}

@media(max-width:@screen-tablet-landscape){

    h1{
        font-size:36px;
        line-height:43px;
    }

    h2{
        font-size:30px;
        line-height:36px;
    }

    h3{
        font-size:24px;
        line-height:30px;
    }

    h4{
        font-size:18px;
        line-height:22px;
    }

    h5{
        font-size:16px;
        line-height:19px;
    }

    p{
        font-size:16px;
        line-height:26px;
    }
}

@media(max-width:@screen-phablet){

    .fade-in{
        opacity:1;
    }

    .move-up{
        margin-top:0px;
        margin-bottom:0px;
    }

    .move-right{
        margin-left:0px;
        margin-right:0px;
    }

    .move-left{
        margin-left:0px;
        margin-right:0px;
    }

    .move-down{
        margin-top:0px;
        margin-bottom:0px;
    }
}
