.site-header {
    margin-bottom: 50px;
    .logo{
      padding: 0 50px;
      img{
          width:150px;
          height:auto;
          margin-top:30px;
      }

      @media(max-width:@screen-tablet-portrait){

      }
    }

}

