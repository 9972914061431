button{
    border: 0px;
    margin: 0px;
    padding: 18px 50px;
    line-height:30px;
    text-align:center;
    font-family:@font-primary;
    font-weight:700;
    text-transform:uppercase;
    font-size: 16px;
    letter-spacing: 2px;
    position:relative;
    transition:@trans-all;

    span{
        position:relative;
        z-index:2;
    }

    &:before{
        content:" ";
        display:block;
        background-color:black;
        width:100%;
        height:100%;
        transform: translate(-50%, -50%);
        position: absolute;
        left: 50%;
        top: 50%;
        transition:@trans-all;
        opacity:1;
    }

    &:hover{
      outline: none;

        &:before{
            width:0px;
            height:0px;
            opacity:0;
        }

        &:focus{
          outline: none;
        }
    }

    &.btn-primary{
        background-color: #d88400;
        color:@primary-white;

        &:before{
            background-color:#E28C06;
        }

        &:hover{
            color:white;
        }
    }

    &.btn-secondary{
        background-color: @primary-white;
        color:white;

        &:before{
            background-color:@brand-primary;
        }

        &:hover{
            color:@brand-primary;
        }
    }

    &.btn-tertiary{
        background-color: @primary-dark;
        color:white;

        &:before{
            background-color:@brand-primary;
        }

        &:hover{
            color:@brand-primary;
        }
    }
}

button.next.answer{
  margin: 0 auto;
  display: block;
}

//Custom radio buttons on questions page
.radio-buttons{
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
  .radio-btn{
    color: #AAAAAA;
    display: block;
    position: relative;
    float: left;
    width: 15px;
    height: 42px;
  }

  .radio-btn input[type=radio]{
    position: absolute;
    visibility: hidden;
  }

  .radio-btn label{
    display: block;
    position: relative;
    font-weight: 300;
    font-size: 14px;
    height: 60px;
    z-index: 9;
    cursor: pointer;
    transition: all 0.15s linear;
    text-align: center;
    color: #231F20
  }

  .radio-btn:hover label{
  	color: @brand-primary;
  }

  .radio-btn .check{
    display: block;
    position: absolute;
    background-color: @radio-bg;
    border: 1px solid @radio-border;
    border-radius: 100%;
    height: 22px;
    width: 22px;
    bottom: 0;
    left: 0;
  	z-index: 5;
  	transition: all .15s linear;
  }

  .radio-btn .check::before {
    display: block;
    position: absolute;
  	content: '';
    border-radius: 100%;
    height: 11px;
    width: 11px;
    top: 2px;
  	left: 2px;
    margin: auto;
  	transition: background 0.15s linear;
  }

  input[type=radio]:checked ~ .check{
    background: black;
  }
}

@media(max-width:@screen-phablet){
  .radio-buttons{
    .radio-btn{
      width: 20%;
      display: block;
      margin-bottom: 20px;
    }

    .radio-btn .check{
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}
