body.modal-open {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.overthrow-enabled .overthrow {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.overflow {
  height: 100%;
  padding: 15px !important;
}

.modal {
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 40;
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  transform: translate3d(0, 0, 0);


  h1, h2, h3, h4, h5, h6, p{

  }

  .modal-wrapper {
    max-width: 625px;
    display: block;
    margin: 0 auto;
    position: relative;
    margin-bottom: 50px;
    margin-top: 100px;
    border: none;
    padding: 45px 0;
    background-color: #FFFFFF;

    .close-btn{
      position: absolute;
      right: 20px;
      top: 20px;
      font-size: 20px;
      transition: all .25s;

      &:hover{
        cursor: pointer;
        transform: scale(1.2);
      }
    }
  }

  .modal-panel {
    width: 100%;
  }


  .modal__header {
    margin-bottom: 20px;
    text-align: center;
    img{
      width: 213px;
    }
  }

  .modal__content {
    padding: 0 60px;

    .text-inputs {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      input {
        width: 49%;
        margin-bottom: 12px;
        padding: 10px 20px;
        outline: 0 !important;
        border: 1px solid #81AAC3;
        color: #A2A0A1;
        background-color: #F7F7F7;

        &.email{
          width: 100%;
        }

        &::placeholder{
          color: #A2A0A1;
        }
      }
    }

    .select-input{
      border: 1px solid #81AAC3;
      width: 100%;
      height: 42px;
      text-align: center;
      background-image: url(/img/templates/2/site/select-arrow.png);
      background-repeat: no-repeat;
      background-size: 9px;
      background-position: 97% 50%;
      margin-bottom: 12px;
      background-color: #F7F7F7;
      color: #A2A0A1;

      select{
        background: none;
        border: none;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        padding: 0 21px;
        -webkit-appearance: none;
      }
    }

    .separator {
      background-color: #D0DBE2;
      width: 100%;
      height: 1px;
      margin-bottom: 20px;
    }
  }

  .modal__footer {
    padding: 46px 0 0;
    p {
      text-align: center;
      font-size: 15px;
      line-height: 1;
    }
  }

  .form-submit{
    width: 100%;
    font-weight: 400;
  }

  .validation-errors {
    .modal__content {
      span.invalid-postcode {
        display: block;
      }
    }
  }

  @media screen and (max-height: 820px) {
    height: auto;

    .overflow {
      height: auto;
    }

    .modal-wrapper {
      top: 0px;
      transform: none;
    }
  }

  @media screen and (max-width: @screen-phablet) and (max-height: 990px) {
    .modal-wrapper {
      top: 0px;
      transform: none;
    }
  }


  @media screen and (max-width: @screen-phablet) {

    img.logo {
      display: none;
    }

    .modal__header {
      .flex {
        flex-direction: column;

        img {
          margin-right: 0px;
          margin-bottom: 10px;
        }

        h4 {
          text-align: center;
          max-width: 300px;
        }
      }
    }

    .modal__content {
      .text-inputs {
        input {
          flex-basis: 100%;
        }
      }
      .gender {
        margin-top: 15px;
        flex-wrap: wrap;
        span {
          margin: 0px;
          flex-basis: 100%;
          margin-bottom: 10px;
        }
        .radio {
          margin-right: 15px;
        }
      }
    }
  }
}

.validate{
  height: 42px;
  margin-top:25px;

  .spinner{
    display: none;
    text-align: center;
  }
}


body.modal-open {
  .modal {
    background-color: rgba(0, 0, 0, 0.87);
  }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

@media screen and (max-width: @screen-phablet) {
  .modal-panel {
    padding-left: 20px;
    padding-right: 20px;
  }

  .modal .modal__content{
    padding:0px 0px;
  }

  .modal{

    button{
      padding: 10px 20px;
    }
  }
}
