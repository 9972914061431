body.templates-2-home,body.templates-2-homeRetail{
    overflow-x: hidden;
    background-color: #112E3D;

}

@keyframes scrolldown {
  0%, 100% { margin-top:0px;margin-bottom:0px;}
  75% { margin-top:5px;margin-bottom:-5px;}
}

.page-home {

    .banner{
        padding-top: 73px;
        background-color: @primary-dark;
        background-image: url(/img/templates/2/site/hero-bg.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 585px;
        position: relative;

        .container{
            position: relative;
            z-index: 2;
        }
        img.lines{
            max-width: 1015px;
            width: 100%;
            position: absolute;
            z-index: 1;
            bottom: 23px;
            left: 0;
            right: 0;
            margin: auto;
        }


        .row{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-content: stretch;
            align-items: center;
        }

        .chart{
            text-align: center;
            img{
                max-width: 507px;
                width: 100%;
            }
        }

        .banner-text{

            p{
                margin-bottom:15px;
                color: #231F20;
                font-weight: 400;
                font-size: 20px;
            }

            h1{
               font-family: @font-secondary;
               color: #FFFFFF;
               font-weight: 400;
               font-size: 40px;
               line-height: 1.3;
               margin-bottom: 10px;
                 span{
                   display: block;
                   font-size: 25px;
                 }
            }

            button{
                margin-top: 15px;
            }
        }

        .scroll-down{
            margin-top:50px;
            cursor:pointer;
            transition:@trans-all;
            width:120px;

            img, span{
                margin-right:5px;
                transition:@trans-all;
            }

            &:hover{
                opacity:0.8;

                img{
                    animation: scrolldown 1s infinite;
                }

                span{
                    opacity:0;
                }
            }
        }
    }

    .about{
        padding: 30px 0;
        .left-column{
            padding-top: 33px;
            p{
                font-size: 20px;
                font-weight: 400;
            }

            ul{
                padding-left: 22px;
                margin-top: 24px;
                font-size: 16px;
            }
        }

        .right-column{
            text-align: center;
            img{
                max-width: 605px;
                width: 100%;

            }
        }
    }

    .how{
        margin:50px 0;

        h3{
            font-size: 25px;
            color: #231F20;
            font-weight: 400;
            margin: 0 0 25px 0;
        }

        .categories{
            display: flex;
            justify-content:center;
            flex-wrap: wrap;

            .category{
                display: flex;
                padding: 15px;
                width: 20%;

                .category-content{
                    padding:0 20px 10px;
                    background-color: @brand-primary;
                    border: 2px solid #F3DCCA;
                    width: 100%;
                    h4,p{
                        color: #FFFFFF;
                        font-weight: 400;
                    }

                    h4{
                        font-size: 27px;
                        border-bottom: 2px solid #F2BB93;
                        padding-bottom: 12px;
                    }

                    p{
                        font-size: 16px;
                        line-height: 1.4;
                    }
                }

                .image{
                    margin:0 auto;
                    width:129px;
                    height:129px;
                    margin-bottom:20px;
                    position:relative;

                    img{
                      width: 129px;
                      height: auto;
                    }
                }
            }
        }
    }

    .testimonials{
        padding: 62px 0 50px;
        background-image: url(/img/templates/2/site/quote-bg.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        .slide{
            outline:none;
            color: #FFFFFF;

            .text{
                font-size: 20px;
                text-align: center;
            }

            .name, .position{
                margin-top:10px;
                text-transform: uppercase;
                font-weight: 600;
                color: @brand-primary;
            }

            .name{
                font-size:14px;
                text-align:center;
            }

        }

        .slick-dots{
            li{
                width: 6px;
                height: 6px;
                margin: 0 6px;

                button{
                    width: 6px;
                    height: 6px;
                    padding: 0;
                    position: absolute;
                    left: 3px;
                    &:before{
                        opacity: 1;
                        top: initial;
                        width: 6px;
                        height: 6px;
                        border-radius: 100%;
                        content: '';
                        background-color: #868686;
                    }
                }

                &.slick-active{
                    button{
                        &:before{
                            background-color: @brand-primary;
                        }
                    }
                }
            }
        }
    }

    .discover-score{
        padding:100px 0;
        background-color:@primary-dark;

        h2{
          font-size: 48px;
        }

        h3{
          font-size: 36px;
        }

        h2, h3, p{
            color:white;
        }

        p{
            margin:30px 0;
        }

        img{
            padding-bottom:50px;
        }

        .row{
            margin-bottom:60px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-content: stretch;
            padding-top:30px;

            .discover-item{

                &:nth-child(1){

                    img{
                        margin-top:15px;
                    }
                }
            }
        }
    }

    .steps{
        background-image: url(/img/templates/2/site/steps-bg.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 30px 0 70px;
        text-align: center;

        h2,p{
            color: #FFFFFF;
            font-size: 20px;
            font-weight: 400;
            font-family: @font-primary;
            line-height: 1.6;
        }

        h2{
            position: relative;
            margin-bottom: 40px;
            &:after{
                content: "";
                width: 188px;
                height: 1px;
                border-bottom: 1px solid #F4CC97;
                bottom: -23px;
                left: 0;
                right: 0;
                margin: auto;
                position: absolute;
            }
        }
        .step{
            padding: 0;
            margin-top: 30px;
            color: #FFFFFF;
            text-align: left;
            h2{
                font-size: 32px;
                margin: 0;
                padding: 0;
                line-height: 1;
                font-family: @font-secondary;
                font-weight: 600;

                &:after{
                    display: none;
                }
            }

            p{
                font-size: 16px;
                color: #231F20;
                font-family: @font-primary;
                font-weight: 400;
                min-height: 65px;
            }
            img{
                width: 100%;
            }
        }

        button{
            margin-top: 40px;
        }
    }

    @media(max-width:@screen-laptop){
      .how{
        .categories{
          .category{
            .category-content{
              h4{
                font-size: 20px;
              }
            }
          }
        }
      }
    }

    @media(max-width:@screen-tablet-landscape){
      .banner{
        height: auto;
        padding-bottom: 45px;

        .banner-text{
          h1{
            font-size: 39px;
          }

          p{
            font-size: 20px;
          }
        }
      }

      .how{
        .categories{
          .category{
            width: 33%;
          }
        }
      }

    }

    @media(max-width:@screen-phablet){

      .how{
        .categories{
          .category{
            width: 50%;
            padding: 8px;
          }
        }
      }

      .steps{
        padding: 30px 20px 70px;
      }
    }
}

.pragma-signup-form{
    max-width: 560px;
    width: 100%;
    margin: 0 auto;
    background-color: #0f2733;
    padding-top: 53px;
    padding-bottom: 78px;
    .modal__content {
        padding: 0 60px;
    
        .text-inputs {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
    
          input {
            width: 49%;
            margin-bottom: 12px;
            padding: 10px 20px;
            outline: 0 !important;
            color: #052e40;
            background-color: #ece6d3;
    
            &.email{
              width: 100%;
            }
    
            &::placeholder{
              color: #052e40;
            }
          }
        }
    
        .select-input{
          width: 100%;
          height: 42px;
          text-align: center;
          background-image: url(/img/templates/2/site/select-arrow.png);
          background-repeat: no-repeat;
          background-size: 9px;
          background-position: 97% 50%;
          margin-bottom: 12px;
          background-color: #ece6d3;
          color: #052e40;
    
          select{
            background: none;
            border: none;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            padding: 0 21px;
            -webkit-appearance: none;
          }
        }
    
        .separator {
          background-color: #D0DBE2;
          width: 100%;
          height: 1px;
          margin-bottom: 20px;
        }

        .btn-primary.form-submit{
            width: 100%;
        }
      }
}
