body.templates-2-questions{
    background-color: #112E3D;
    height: 100%;


}

.templates-2-questions {

  .btn-primary.next,.btn-primary.finish{
    background-color: #E28C06;
    color: #FFFFFF;
    padding: 12px 20px;
    width: 200px;
    text-align: center;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
    float: right;
    margin: 20px 0;

    &:hover{
      cursor: pointer;
    }
  }

  // .btn-primary.finish{
  //   display: none;
  // }

    .questions-container{
        height: 550px;
        margin-bottom: 100%;

        &.type-2{
          height: 630px;

          .question-content{
            margin-bottom: 0;
          }
        }
    }

    .section-questions .container{
      background-color: #ECE6D6;
      max-width: 1062px!important;
      width: 100%;
    }

    .card-bg{
      background-color: #112E3D;
      .card-2{
        width: 92%;
        height: 10px;
        background-color: #5B646F;
        margin: 0 auto;
      }

      .card-3{
        width: 97%;
        height: 10px;
        background-color: #4A4A4A;
        margin: 0 auto;
      }
    }

    .questions-header{
      background-color: #112E3D;
      max-width: 1062px;
      width: 100%;
      margin: 0 auto;
      position: relative;
      text-align: left;
      position: fixed;
      top: 0;
      z-index: 100;
      padding-top: 30px;

      img{
        width: 150px;
        margin-bottom: 30px;
      }

      .header-main{
        background-color: #ECE6D6;
        padding: 20px 40px 0;
        display: flex;
        justify-content: space-between;
      }

      .header-left{
        width: 50%;
      }

      .back-arrow{
          font-size: 20px;

          a{
              color: #231F20;
          }

          span, i{
              float:left;
          }

          span{
              font-size: 18px;
              margin-left: 10px;
              line-height:26px;
              height:26px;
          }

          i{
              font-size:26px;
              line-height:26px;
              height:26px;
              transition:@trans-all;
              margin-right: 5px;
          }

          &:hover{
            cursor: pointer;

              i{
                  margin-left:-5px;
                  margin-right:5px;
              }
          }
      }

      .header-scale{
        width: 50%;
        font-size: 16px;
        font-weight: bold;

        .key{
          display: flex;
          justify-content: space-between;
          padding: 0 15px;

          p{
            font-size: 16px;
            font-weight: bold;
          }
        }
        ul{
          padding: 0;
          margin: 0;
          display: flex;
          justify-content: space-between;
          width: 100%;
          li{
            list-style: none;
            width: 22.2%;
            text-align: center;
          }
        }
      }
    }

    .questions-wrapper{
      max-width: 1062px;
      width: 100%;
      margin: 0 auto;
      background-color: #ECE6D6;
      padding: 0 40px 20px;
    }

    .questions-set{
      display: none;
      &:nth-of-type(1){
        display: block;
      }

      .btn-primary.finish{
        display: none;
      }

      &:last-of-type{
        .btn-primary.finish{
          display: block;
        }
      }
    }

    .section-questions {
        opacity: 0;
        padding: 0 20px;
        transition: opacity .4s;
        margin-top: 30px;

        .loader {
            display: none;
            width: 65px;
            margin: 0 auto;
            margin-top: 15%;

            img {
                max-width: 100%;
            }
        }

        .container{
          .row{
            position: relative;
            padding-top: 166px;
          }
        }


        .question {
            margin: 20px auto 0;
            outline: 0;

            width: 100%;
            padding: 0;
            list-style: none;
            border-radius: 0;
            clear: both;

            p{
              color: #231F20;
            }



            .prog{

                .number-done{
                    text-transform: uppercase;
                    color: @brand-primary;
                }
            }

            &.active {
                //display: block;
            }

            .question-content{
                width: 50%;
                float: left;
            }

            .radio-answer{
              width: 50%;
              float: left;
            }


            .question-footer{
                position: absolute;
                bottom:0;
                left: 0;
                width: 100%;
                height: 50px;
                line-height: 48px;
                text-align: center;
                font-size: 18px;
                font-family: 'montserrat';
                font-weight: 600;
                color: #B2B2B2;
                border-top: solid #E3E9F1 2px;
                background-color:#FBFBFB;
            }

            .buttons{

                button{
                    margin:0 10px;
                }
            }

            span.error-msg{
              color: red;
              display: block;
              text-align: center;
              margin-top: 5px;
            }
        }

        .radio-scale{
          color: #231F20;
          text-transform: uppercase;
          position: relative;
          height: 24px;

          .poor{
            position: absolute;
            top: 0;
            left: 0;
          }

          .excellent{
            position: absolute;
            top: 0;
            right: 0;
          }
        }

        .buttons {
            text-align: center;
        }

        &.show-questions{
          opacity: 1;
        }
    }

    .page-count{
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      padding: 0 0 35px;
    }

    /*
        --Cardslider
    */

    .cardslider__cards{
      top: 60%;
    }

    .cardslider--direction-down .cardslider__card--out {
      transform: translate3d(0, 100%, 4px);
      opacity: 0!important;
    }

    .cardslider--direction-down .cardslider__card{
        background-color:#D8D8D8;
        transition:@trans-all;
        border-radius:5px;
    }

    .cardslider--direction-down .cardslider__card--index-0{
        opacity:1;
        background-color: #F0F0F0;
        box-shadow: 0 4px 6px 0 rgba(0,0,0,0.10);

    }

    .cardslider--direction-down .cardslider__card--index-1{
        opacity:1;
        background-color: #4A4A4A;
    }

    .cardslider--direction-down .cardslider__card--index-2{
        opacity:1;
        background-color: #5B646F;
    }


    /*
        -- Bottom Bar
    */

    .section-banner {
        background-color: @brand-primary;
        height: 125px;

        .container {
            height: 125px;

            .row {
                display: flex;
                align-items: center;
                height: 125px;

                p {
                    margin-left: 215px;
                }

                img {
                    position: relative;
                    top: -60px;
                    margin-left: 40px;
                    margin-right: 215px;
                }
            }
        }
    }

    .site-footer {
        .footer-nav {
            display: none;
        }
    }

    @media(max-width:@screen-laptop){

    }



    @media(max-width:@screen-phablet){

    }
}

@media(max-width:@screen-tablet-landscape){
  .questions-header{
    width: inherit!important;
    margin: 0 20px!important;
    left: 0;
    right: 0;
    .header-main{
      flex-direction:column!important;
      .header-left{
        width: 100%;
        float: none;
      }

      .header-scale{
        width: 100%;
        float: none;
        margin: 0;
        .key{
          padding: 0!important;
        }
      }
    }
  }

  .section-questions{
    .question{
      margin: 0 auto 40px;
      .question-content{
        width: 100%!important;
        float: none!important;
        padding: 0 0 10px;

        p{
          margin-bottom: 0;
        }
      }

      .radio-answer{
        width: 100%!important;
        float: none!important;
        margin-top: -20px;
      }
    }
  }

  .templates-2-questions .btn-primary.next, .templates-2-questions .btn-primary.finish {
    width: 100%;
  }
}

.radio-buttons .radio-btn .check{
  background-color: #ece6d6;
}