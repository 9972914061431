body.templates-2-results {
    font-family: 'Work Sans', sans-serif;

    header.site-header{
      background-color: #FBEFE1;
      padding: 20px 30px;
    }

    .scores-banner{
      color: #FFFFFF;
      background-color: #112E3D;

      .container-fluid{
        padding: 0;
      }

      .row{
        display: flex;
        align-items: center;
      }

      h2{
        color: #FFFFFF;
        font-size: 40px;
        line-height: 56px;
        font-weight: 200;
      }

      .score-bar-outer{
        width: 100%;
        background-color: #FFFFFF;
        border: 4px solid #FFFFFF;
        border-radius: 23px;
        overflow: hidden;
        position: relative;

        .score-bar{
          height: 36px;

          &.low{
            background-color: #BE112E;
          }

          &.medium{
            background-color: #FFC500;
          }

          &.high{
            background-color: #10B847;
          }
        }

        span{
          height: 36px;
          width: 6px;
          background-color: #FFFFFF;
          position: absolute;

          &:nth-of-type(1){
            left: 20%;
            top: 0;
          }

          &:nth-of-type(2){
            left: 40%;
            top: 0;
          }

          &:nth-of-type(3){
            left: 60%;
            top: 0;
          }

          &:nth-of-type(4){
            left: 80%;
            top: 0;
          }
        }
      }

      .score-key{
        display: flex;
        justify-content: center;
        margin-top: 20px;
        .key{
          float: left;
          display: flex;
          margin: 0 10px;
          color: #000000;
          font-weight: bold;
          .indicator{
            width: 24px;
            height: 24px;
            border-radius: 100%;
            background-color: #FFFFFF;
            margin-right: 10px;

            &.low{
              background-color: #BE112E;
            }

            &.medium{
              background-color: #FFC500;
            }

            &.high{
              background-color: #10B847;
            }
          }
        }
      }

      .scores-row{
        display: flex;

        @media only screen and (max-width: 768px) {
          flex-direction: column;
        }

        .about{
          width: 50%;
          padding: 75px;
          color: #E3CEAD;
          position: relative;

          @media only screen and (max-width: 768px) {
            width: 100%;
            padding: 75px 21px;
          }

          p{
            color: #ECE6D6;
          }

          .results-logo{
            width: 150px;
            position: absolute; 
            top: 20px;
            left: 20px;
          }
        }

        .overall-score-outer{
          width: 50%;
          background-image: url(/img/templates/2/site/wave-bg-results.png);
          background-size: cover;
          background-repeat: no-repeat;
          display: flex;
          justify-content: center;
          align-items: center;

          @media only screen and (max-width: 768px) {
            width: 100%;
            background-image: url(/img/templates/2/site/wave-bg-results-mobile.png);
            padding: 134px 0 80px;
          }

          .overall-score{
            width: 275px;
            margin-left: 20%;

            @media only screen and (max-width: 768px) {
              margin: 0 auto;
            }


            .text,.percentage{
              color: #112E3D;
            }
          }
        }
      }
    }

    .overall-score{
        width: 100%;
        text-align: center;
        .text{
	          font-size: 20px;
            font-weight: 600;
            line-height: 32px;
            font-family: @font-secondary;
        }

        .percentage{
            color: #FFFFFF;
	          font-size: 80px;
            font-weight: 600;
            line-height: 80px;
            font-family: @font-secondary;
            margin: 20px 0 15px;
        }
    }

    .change-email{
        cursor:pointer;
        transition:@trans-all;
        font-size: 16px;


        &:hover{
            text-decoration: underline;
        }
    }

    .about{

      h2{
        color: #ECE6D6;
      }

        .subheader{
            font-size:24px;
            line-height:29px;
            color:@brand-primary;
            margin:20px 0;
            font-weight: 300;
        }

        p{
        	font-size: 20px;
          line-height: 32px;
        }
    }

    .categories{
        display: flex;
        justify-content:center;
        flex-wrap: wrap;

        .category{
            display: flex;
            padding: 15px;
            width: 20%;
            border-radius: 100%;
            width: 228px;
            height: 228px;

            .category-content{
                padding: 15px;
                position:relative;
                background-color: #ECE6D6;
                color: #112E3D;
                width: 100%;
                border-radius: 100%;
                text-align: center;
                overflow: hidden;
                padding-bottom: 41px;
                border: 2px solid #ECE6D6;

                .title{
	                  font-size: 20px;
                    line-height: 32px;
                    font-family: @font-secondary;
                    border-bottom: 1px solid #FFFFFF;
                    padding-bottom: 3px;
                    margin-bottom: 15px;
                }

                p{
                  	font-size: 15px;
                    line-height: 20px;
                    color: #112E3D;
                }

                .score{
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  flex-direction: column;
                  background-color: blue;
                  height: 44px;
                  padding-top: 5px;
                  position: absolute;
                  bottom: 0;
                  width: 100%;
                  left: 0;

                  &.red{
                    background-color:#AF2934;
                  }

                  &.yellow{
                    background-color:#F7C644;
                  }

                  &.green{
                    background-color: #53B456;
                  }

                  .bar{
                    width: 90px;
                    height: 15px;
                    border: 2px solid #FFFFFF;
                    border-radius: 15px;
                    margin-right: 15px;
                    display: none;
                    &.red{
                      background-color:#FF010A;
                    }

                    &.yellow{
                      background-color:#FFE86C;
                    }

                    &.green{
                      background-color:#53B456;
                    }
                  }
                    .value{
	                    font-size: 24px;
                      font-family: "PP Woodland";
                      line-height: 32px;
                      color: #112E3D;
                    }
                }
            }
        }
    }

    .report{
      padding: 50px 0;
      background-color: #ECE6D6;
      .row{
        display: flex;
        align-items: center;
      }

      .subheader{
        p{
          font-size: 20px;
          color: #112E3D;

          @media only screen and (max-width: 768px) {
            font-size: 16px;
          }
        }

        .change-email{
          text-decoration: underline;
        }
      }
    }

    .section-social-shares{
        background-color: #112E3D;
        padding: 50px 0;
        color:white;

        h2{
            margin-top:0px;
            color:#ECE6D6;
            margin-bottom:20px;
            font-family: 'Work Sans', sans-serif;
            font-size: 30px;
        }

        .total-shares{
            border-right:1px solid white;

            .js-total{
                display:block;
                font-size:64px;
                line-height:77px;
                font-weight:700;
            }

            .label{
                font-weight:700;
                font-size:24px;
                line-height:29px;
                padding:0px;
                color:white;
            }
        }

        .share-option{

            .link{
                display: block;
                width: 70px;
                height: 70px;
                border: 1px solid white;
                border-radius: 70px;
                text-align: center;
                line-height: 70px;
                font-size: 30px;
                margin: 0 auto;
                margin-bottom: 10px;
                color: #ECE6D6;
                transition:@trans-all;

                &:hover{
                    opacity:0.7;
                }
            }

            .share-number{
                text-align:center;
                color:white;
                font-weight:700;
                font-size:18px;
                font-family:@font-secondary;
                text-align:center;
                display:block;
            }
        }
    }

    #changeemail-popup {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        visibility: hidden;
        opacity: 0;
        transition: all 0.4s;

        &.show {
            opacity: 1;
            visibility: visible;
        }

        .overlay {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: absolute;
            background: rgba(14, 36, 45, 0.7);
            height: 100%;
            width: 100%;
        }

        form {
            max-width: 300px;
            margin: 0 auto;
            background-color: @results-primary;
            padding: 25px;

            .circle {
                position: absolute;
                top: -12px;
                right: -9px;
                cursor: pointer;
                background-color: #0e242d;
                border-radius: 50%;
                width: 23px;
                height: 23px;
                text-align: center;
                background-color: #0e242d;

                i {
                    color: #fff;
                    line-height: 22px;
                }
            }

            input[type="text"] {
                width: 100%;
                margin-bottom: 20px;
                background-color: #FBFBFB;
                font-size:14px;
                padding: 15px 20px;
                outline: 0 !important;
                border: 1px solid #F2f2f2;
            }

            input[type="submit"] {
                width: 100%;
            }
        }
    }

    @media(max-width:@screen-laptop){

        .categories{

            .category{
                padding: 20px;
            }
        }
    }

    @media(max-width:@screen-tablet-landscape){

      .scores-banner{
        .row{
          display: block;
        }
      }

        .categories{

            .category{
                width: 228px;
                height: 228px;
            }
        }
    }

    @media(max-width:@screen-tablet-portrait){

        .logo{
            padding: 15px;
        }

        .about{

            .subheader{
                font-size:18px;
                line-height:22px;
            }
        }

        .overall-score{
            margin:0 auto;
        }

        .categories{

            .category{
              width: 240px;
              height: 240px;
            }
        }

        .total-shares{
            text-align: center;
            margin-bottom: 30px;
            border:0px !important;
        }

        .shares{
            padding: 0 15px;

            .share-option{
                margin-bottom:15px;
            }
        }
    }
    
    .category-wrapper{
      background-color: #5674AE;
      padding: 50px 0 100px;

      h2{
        color: #ECE6D6;
        margin-bottom: 45px;
        font-weight: 200;
      }
    }
}
