footer.site-footer{
    background-color: #231F20;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    img{
        width: 156px;
        float: left;
    }

    ul{
        float:right;
        margin: 0;
        padding: 0;
        li{
            margin: 0 0 0 40px;
            padding: 0;
            list-style: none;
            display: inline-block;
            a{
                color: #FFFFFF;
                font-size: 12px;
            }
        }
    }
}